import React from 'react';

import { graphql } from 'gatsby';
import { MarkdownRemarkLookupQuery } from '../../types/graphql-types';

import Footer from '../components/Footer';
import LegalDocsHeader from '../components/LegalDocsHeader';
import SiteHelmet from '../components/SiteHelmet';
import legalDocsTemplateStyles from './legalDocsTemplate.module.css';

interface IProps {
  data: MarkdownRemarkLookupQuery;
}

export const pageQuery = graphql`
  query markdownRemarkLookup($path: String!) {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

const Template = ({ data }: IProps) => {
  const { markdownRemark, site } = data;
  const title =
    markdownRemark &&
    markdownRemark.frontmatter &&
    markdownRemark.frontmatter.title;
  const html = (markdownRemark && markdownRemark.html) || '';

  const siteMetadata = site && site.siteMetadata;

  return (
    <React.Fragment>
      <SiteHelmet siteMetadata={siteMetadata} />
      <div className={legalDocsTemplateStyles.blogPostContainer}>
        <LegalDocsHeader />
        <div className={legalDocsTemplateStyles.blogPost}>
          <h1>{title}</h1>
          <div
            className={legalDocsTemplateStyles.blogPostContent}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Template;
