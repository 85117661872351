import React from 'react';

import splashStyles from '../sections/Splash.module.css';
import legalDocsHeaderStyles from './LegalDocsHeader.module.css';

const LegalDocsHeader = () => (
  <div className={legalDocsHeaderStyles.container}>
    <a href="/">
      <div className={splashStyles.splashLogoContainer}>
        <span className={legalDocsHeaderStyles.title}>USI Hackathon 2019</span>
      </div>
    </a>
  </div>
);

export default LegalDocsHeader;
